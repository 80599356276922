import React from 'react';

const WorkExperience = () => (
  <div className="flex container max-w-screen-lg m-auto px-4 flex-col">
    <div>
      <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-400 sm:text-4xl sm:leading-10">
        Work Experience
      </h2>
    </div>

    <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12">
      <dl>
        <div className="md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Havas Lynx Group
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Web developer
              <br />
              April 2020 - Present
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">Over the past 4 years I have been working as a developer within a multi-discipline agile team consisting of; BA, PM, QA, UX and Creatives. My primary tech stack consists of JavaScript, React, HTML, CSS and Node.js, however I have knowledge in other areas. I’ve gained valuable experience of working with AWS services such as S3, CloudFront and Lambdas. I have been responsible for the delivery life cycle of both individual projects and enterprise software products, working with internal and external stakeholders to create and manage product/project roadmaps as well as feature definitions. I am experienced in a full development cycle from sprint planning, development and demoing new features in internal and external client retrospectives. I am comfortable in contributing to the facilitation and attendance of client workshops, defining project requirements and creating statements of work.<br /><br />
            
            I have had the opportunity to be a mentor to multiple different Junior Developers in this role, which involved weekly 1-to-1 chats, also general day to day code-based queries in which I would demonstrate best practices and coding standards to help them progress. <br /><br />

            <b className="underline">Project Highlights</b><br />
            <div className='mb-2'>- Created an internal tool which gives a no-code solution for Artworkers to create interactive IVAs.<br /></div>
            <div className='mb-2'>- I was part of a team which created an internal learning platform that allowed users to book, manage and add feedback to internal sessions, which I then led and was responsible for any change requests or updates.<br /></div>
            <div className='mb-2'>- I have been a key part of a team which created a multi-platform (Web, Desktop, iOS, Android) enterprise software product for a major pharmaceutical company, allowing users to sync, view, download, share compliant sensitive pharmaceutical materials in multiple formats.<br /></div>
            <div className='mb-2'>- Worked with Three.js and BabylonJS to create a virtual 3D environment to surface content in response to the office COVID 19 pandemic.<br /><br /></div>

            Highlight - Over the past 4 years, over multiple projects, I have led on the technical roadmap strategy for a core Product workstream within the team, which has allowed me to hone my skills around combining user needs and the long-term digital strategy.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Havas Lynx Group
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Junior Web developer
              <br />
              June 2018 - April 2020
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">I began learning the trade as a Junior Developer where I worked on smaller projects and simple email builds and banners, to strengthen my understanding of the core foundation languages. I developed a full frontend of a data visualisation tool which was built using React and Styled Components. The tool allowed a user to filter, search and sort a parsed CSV file into a visually pleasing site displaying animated graphs and data tables. On a day-to-day basis I would work with the UX designer for this project to implement an optimised tool with precision of coding standards, all whilst improving my knowledge of the design tool Figma. The backend was developed using NodeJS and MongoDB. Although my main role was the frontend, this gave me insights on how to understand a backend system in which a file would be uploaded to AWS SES and AWS Lambda to parse the data, in which I would pull it down and distribute in the frontend.<br /><br />

            During my first eighteen months at Havas Lynx, I was on the ‘Graduate’ programme; a specialist course within Havas which explores a wide range of topics from medical information to Strategy and Client Service. A core aspect within this course was presenting, with bi-weekly presentations to the wider members of the scheme - approximately 30 people. With the final month of the programme culminating in a group project, and pitch. Within my multidisciplinary team, I was the technical expert and worked with my colleagues to create an interactive experience for our final presentation.<br /><br />

            Highlight – As well as learning the technical trade in the core languages and frameworks, this period has provided me with great confidence and experience when presenting to many people. It has taught me the skills in which to address myself clearly and concisely in a professional manner, whilst effectively connecting with the audience in a comfortable way.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              The Fountain Company Ltd
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Computer Aided Design Technician
              <br />
              September 2015 - June 2018
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">One of the main tasks within my role centred around the 3D engineering drawings which would be used for the various outputs (such as, underground pipework, plantrooms, water features and fountains). Once these had been developed, I was heavily involved with the presentations of these plans to clients, to ensure that all was in line with expectations prior to the commencement of implementation.<br /><br />

            Thanks to being a small team, I was able to work on many of the elements around the running, and operation, of the business; I was personally responsible for triaging incoming enquiries and any new business requests, along with the development of these through to a secured lead.<br /><br />

            Highlight - Thanks to being in this role for a few years, I was able to push myself significantly and was given the scope to explore new opportunities and responsibilities. As a starting point, it was within this role where I truly built my work ethic and imparted core principles around discipline and delivery.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              AMH Air Conditioning Ltd
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Air Conditioning Technician Apprentice
              <br />
              March 2015 - September 2015
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">Carrying out the installation, servicing, and maintenance of Air Conditioning units. Within this role, I worked directly on site with clients, both commercial and private, where attention to detail was key.<br /><br />

            Highlight - A key client within this role was Jaguar Land Rover, with this being a long-term contract I was able to build strong relationships with the core team, which was especially important with the bulk of the role being onsite.
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
);

export default WorkExperience;
