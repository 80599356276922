import React, { useRef } from 'react';
import Headroom from 'react-headroom';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnimatedBackground from '../components/animatedBackground';
import WorkExperience from '../components/work-experience';
// import Articles from '../components/articles';
import { HomeHero, HeadroomWrapper, HeaderButton } from '../styles/App.styles';
import CookiePopup from '../components/cookiePopup';
import jhLogo from '../images/favicon-32x32.png';

const IndexPage = () => {
  const homeHeroSection = useRef(0);
  const aboutSection = useRef(0);
  const workExperienceSection = useRef(0);
  // const articlesSection = useRef(0);

  return (
    <Layout>
      <SEO title="Developer" />

      <HeadroomWrapper>
        <Headroom disableInlineStyles>
          <div className="p-4 fixed w-full top-0 z-50" style={{ boxShadow: "0px 6px 10px 0px #303b53", background: "rgba(36, 46, 67, 0.9)" }}>
            <div className="mx-auto flex justify-between text-white max-w-screen-lg">
              <div className="flex">
                <HeaderButton className="hover-underline-animation" onClick={() => { homeHeroSection.current.scrollIntoView({ behavior: 'smooth' }) }}>Home</HeaderButton>
                <HeaderButton className="hover-underline-animation mx-12" onClick={() => { aboutSection.current.scrollIntoView({ behavior: 'smooth' }) }}>About</HeaderButton>
                <HeaderButton className="hover-underline-animation" onClick={() => { workExperienceSection.current.scrollIntoView({ behavior: 'smooth' }) }}>Work Experience</HeaderButton>
                {/* <HeaderButton className="hover-underline-animation" onClick={() => { articlesSection.current.scrollIntoView({ behavior: 'smooth' }) }}>References</HeaderButton> */}
              </div>
              <img src={jhLogo} alt="jh-logo" height="32px" width="32" />
            </div>
          </div>
        </Headroom>
      </HeadroomWrapper>

      <AnimatedBackground />

      <CookiePopup />

      <HomeHero ref={homeHeroSection}>
        <div className="home-hero-container">
          <h1 className="text-5xl text-gray-400 font-extrabold tracking-tight sm:text-5xl">
            Jake Hallam
          </h1>

          <p className="text-xl text-gray-400 tracking-tight sm:text-2xl">Web developer from Manchester</p>
          
          <button className="scroll-down" aria-label="scroll-down" onClick={() => { aboutSection.current.scrollIntoView({ behavior: 'smooth' }) }}></button>
        </div>
      </HomeHero>

      <div className="pt-12 pb-16" ref={aboutSection}>
        <div className="mx-auto max-w-screen-lg mx-auto px-4">
          <div className="relative">
            <div>
              <div className="relative mx-auto max-w-screen">
                <div className="pt-12 sm:pt-16 lg:pt-20">
                  <div className="text-gray-500 space-y-6">
                    <p className="mb-3">A Web and Software Developer with 6 years digital agency experience. Within my current role as Developer at Havas Lynx, I work closely with clients to gather requirements and architect the final solution. Along with the ownership of all technical outputs within workstreams, at Havas Lynx, clients sit within the Pharmaceutical and Health sector, allowing the team to create experiences with a high impact upon the end user.</p>
                  
                    <h3 className="mb-4 mt-6 text-base font-semibold tracking-wider text-indigo-600 uppercase">A few extra things... </h3>

                    <p className="mb-16">I am passionate about football, (avid Leeds United fan) playing locally for over 18 years. Over the past 6 years I have played for Glossop, and prior to this, I played at academy level for Macclesfield Town U13’s - U15’s. Over the warmer seasons, I swap football for cricket, having played for Glossop Cricket Club U17’s whilst playing for High Peak Cricket U15’s. My love of grassroots sport has meant that I have also had the opportunity to coach both Football and Cricket.</p>

                    <h3 className="mb-4 mt-6 text-base font-semibold tracking-wider text-indigo-600 uppercase">Technical skills... </h3>

                    <p className="mb-3">- Core Languages include HTML, CSS, JavaScript<br />
                      - Frontend specialties: React, Typescript, Styled Components, Storybook, Tailwind<br />
                      -	Frontend knowledge: Gatsby, Vue, BabylonJS, JQuery, Remix<br />
                      -	Backend skills involve: AWS Lambda, Node.js, MongoDB, DynamoDB, Wordpress<br />
                      - AWS: S3, Cloudfront, Lambda<br />
                      - Gitlab CI/CD Pipelines, Docker<br />
                      - Photoshop, XD, Figma, Sketch<br />
                      - Agile, Jira, Confluence
                    </p>

                    <h3 className="mb-4 mt-6 text-base font-semibold tracking-wider text-indigo-600 uppercase">Personal Qualities... </h3>
                    
                    <p className="mb-3">- Work with clarity, focus, and speed when respecting tight deadlines<br />
                      - Proven ability to handle pressure<br />
                      - Worked successfully in both individual, and mainly team environments<br />
                      - Attention to detail<br />
                      - Excellent verbal and written communication skills<br />
                      - Confident, a desire and ability to pick up new skills with proactive attitude to learn<br />
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-16 mt-32" ref={workExperienceSection}>
          <WorkExperience />
        </div>

{/* 
        <div className="relative pt-32">
          <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Some Previous Clients Include;</h2>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">AstraZeneca, Pfizer, Manchester City Council, Jaguar, Land Rover</p>
          </div>
        </div> */}

        {/* <div className="pt-16 mt-32" ref={articlesSection}>
          <Articles />
        </div> */}
      </div>
    </Layout>
  )
}

export default IndexPage;
